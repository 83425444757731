
import DropDownButton from "@evercam/shared/components/DropDownButton"
import WeatherExportOptionsDialog from "@/components/weather/WeatherExportOptionsDialog"
import Vue, { PropType } from "vue"
import {
  AnalyticsEvent,
  DownloadTypes,
  MediaType,
  WeatherDataResponse,
} from "@evercam/shared/types"
import {
  downloadCSV,
  exportToCSV,
  uploadFileToTus,
} from "@evercam/shared/utils"
import { EvercamApi } from "@evercam/shared/api/api/evercamApi"
import { weatherIcons } from "@evercam/shared/icons/weatherIcons"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"
import GeneratePdf, { PdfElementType } from "@evercam/shared/mixins/generatePdf"

export default Vue.extend({
  name: "WeatherExportAsBtn",
  components: { DropDownButton, WeatherExportOptionsDialog },
  mixins: [GeneratePdf],
  props: {
    data: {
      type: Array as PropType<WeatherDataResponse[]>,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      exportDialog: false,
      exportType: DownloadTypes.Csv,
      loadingFile: false,
      weatherIcons,
    }
  },
  computed: {
    ...mapStores(useProjectStore, useAccountStore),
    fileTypes(): { name: string; value: DownloadTypes; logo: string }[] {
      return [
        {
          name: "CSV (*.csv) ",
          value: DownloadTypes.Csv,
          logo: require("~/assets/img/csv.png"),
        },
        {
          name: "PDF (*.pdf)",
          value: DownloadTypes.Pdf,
          logo: require("~/assets/img/pdf.png"),
        },
      ]
    },
  },
  methods: {
    handleFileTypeSelection({ value: fileType }: { value: DownloadTypes }) {
      this.$analytics.saveEvent(AnalyticsEvent.WeatherReportExportType, {
        fileType,
      })
      this.exportType = fileType
      this.exportDialog = true
    },
    getWeatherStatus(id: number) {
      return weatherIcons[id]?.descriptionKey ?? ""
    },
    getWeatherStatusDescription({ weather }, index: number) {
      return weather
        ? `${this.$t("content." + this.getWeatherStatus(weather[index].id))}`
        : ""
    },
    prepareExportColumns(columns: string[]) {
      const columnFormatters = {
        Night: (item) => this.getWeatherStatusDescription(item, 0),
        Morning: (item) => this.getWeatherStatusDescription(item, 1),
        Afternoon: (item) => this.getWeatherStatusDescription(item, 2),
        Evening: (item) => this.getWeatherStatusDescription(item, 3),
        "Low (°C)": (item) => `${item.minTemp}°`,
        "High (°C)": (item) => `${item.maxTemp}°`,
        "Min Precip": (item) => `${item.minPrecip}mm`,
        "Max Precip": (item) => `${item.maxPrecip}mm`,
        "Min Wind": (item) => `${item.minWindSpeed}km/h`,
        "Max Wind": (item) => `${item.maxWindSpeed}km/h`,
        "Min Humidity": (item) => `${item.minHumidity}%`,
        "Max Humidity": (item) => `${item.maxHumidity}%`,
        "Min Pressure": (item) => `${item.minPressure}hPa`,
        "Max Pressure": (item) => `${item.maxPressure}hPa`,
        "Min Clouds": (item) => `${item.minClouds}%`,
        "Max Clouds": (item) => `${item.maxClouds}%`,
      }

      return this.data.map((item) => {
        const row = {
          Date: this.$moment(item.date).format("DD/MM/YYYY"),
        }

        columns.forEach((column) => {
          if (column in columnFormatters) {
            row[column] = columnFormatters[column](item)
          }
        })

        return row
      })
    },
    async exportWeatherReportFile(columns: string[] = []) {
      this.$analytics.saveEvent(AnalyticsEvent.WeatherReportExportFile, {
        fileType: this.exportType,
        columns,
      })
      this.loadingFile = true
      const pdfData = {
        "Project Name": this.projectStore.selectedProject.name,
        "Start Date": this.startDate,
        "End Date": this.endDate,
        "Created By": this.accountStore.fullname,
        "Total Days": this.data.length,
      }
      const tableData = this.prepareExportColumns(columns)
      let file = null
      if (this.exportType === DownloadTypes.Csv) {
        const csvData = await exportToCSV({
          origin: "Weather Report",
          data: tableData,
          headers: columns,
          delimiter: ",",
          projectInfo: pdfData,
        })
        file = downloadCSV(
          csvData,
          `weather-report-${this.$moment(this.startDate).format(
            "YYYY-MM-DD"
          )}-${this.$moment(this.endDate).format("YYYY-MM-DD")}.csv`
        )
      } else {
        const { blob } = await this.createPdf({
          pdfData,
          origin: "Weather Report",
          fileName: `weather-report-${this.$moment(this.startDate).format(
            "YYYY-MM-DD"
          )}-${this.$moment(this.endDate).format("YYYY-MM-DD")}.pdf`,
          elements: [
            {
              type: PdfElementType.Table,
              content: tableData,
            },
          ],
        })
        file = blob
      }
      await this.uploadFile(
        file,
        `Weather Report ${this.startDate} - ${this.endDate}`
      )
    },
    async uploadFile(file, title) {
      await uploadFileToTus({
        tusURL: this.$config.public.tusURL,
        file,
        title,
        onSuccess: (upload) => {
          this.saveToMediaHub(
            title,
            upload.url,
            file.type.includes("csv") ? "csv" : "pdf"
          )
        },
        onError: (error) => {
          console.error("Failed because: " + error)
          this.$notifications.error({
            text: "Failed to upload file!",
            error,
          })
        },
      })
    },
    saveToMediaHub(title, fileUrl, fileExtension) {
      let date = new Date()
      const payload = {
        fromDate: date,
        toDate: date,
        title: title,
        type: MediaType.File,
        fileUrl: fileUrl,
        fileExtension: fileExtension,
      }
      this.$analytics.saveEvent(
        AnalyticsEvent.WeatherReportMediaHubUploadFile,
        { payload }
      )

      EvercamApi.mediaHub
        .cCreate(this.projectStore.selectedProjectExid, payload)
        .then(() => {
          this.$notifications.success("File created successfully")
        })
        .catch((error) => {
          if (error?.response?.status !== 401) {
            this.$notifications.error({
              text: "Failed to create file!",
              error,
            })
          }
        })
        .finally(() => {
          this.loadingFile = false
          this.exportDialog = false
        })
    },
  },
})
