
import Vue from "vue"
import { useProjectStore } from "@/stores/project"
import { mapStores } from "pinia"
import { DownloadTypes } from "@evercam/shared/types"

export default Vue.extend({
  name: "WeatherExportOptionsDialog",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: String,
      default: DownloadTypes.Pdf,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        "Night",
        "Morning",
        "Afternoon",
        "Evening",
        "High (°C)",
        "Low (°C)",
        "Min Precip",
        "Max Precip",
        "Min Wind",
        "Max Wind",
        "Min Humidity",
        "Max Humidity",
        "Min Pressure",
        "Max Pressure",
        "Min Clouds",
        "Max Clouds",
      ],
      selectedColumns: [],
    }
  },
  computed: {
    ...mapStores(useProjectStore),
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit("input", value)
      },
    },
    isExportEnabled() {
      return this.selectedColumns?.length > 2
    },
    mediaHubLink() {
      return `${this.projectStore.projectRoute}/media-hub`
    },
  },
  watch: {
    dialog: {
      handler(value) {
        if (value) {
          this.selectedColumns = [
            "Night",
            "Morning",
            "Afternoon",
            "Evening",
            "High (°C)",
            "Low (°C)",
            "Min Precip",
            "Max Precip",
            "Min Wind",
            "Max Wind",
          ]
        }
      },
      immediate: true,
    },
  },
  methods: {
    onSelectAllChanged(value) {
      if (value) {
        this.selectedColumns = this.columns
      } else {
        this.selectedColumns = []
      }
    },
  },
})
